import {
  handleQueryResolve
} from '../utils';

export default function (){
  return this.query(`
  SELECT
    Drg.Manufacturer as Mfr
  FROM
    Pharmacy.dbo.Rx
  INNER JOIN Pharmacy.dbo.Drg ON Pharmacy.dbo.Rx.DrgID = Pharmacy.dbo.Drg.ID
  WHERE
    Drg.Manufacturer <> '' OR Drg.Manufacturer IS NOT NULL
  GROUP BY
    Drg.Manufacturer
  ORDER BY
    Drg.Manufacturer ASC
`).then(handleQueryResolve)
}